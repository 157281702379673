<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">报名列表（小程序）</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">专题报名列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 5px">
            <div title="学员姓名" class="searchboxItem ci-full">
              <span class="itemLabel">学员姓名:</span>
              <el-input
                v-model="realname"
                type="text"
                size="small"
                clearable
                placeholder="请输入学员姓名"
              />
            </div>
            <div title="是否已联系" class="searchboxItem ci-full">
              <span class="itemLabel">是否已联系:</span>
              <el-select
                clearable
                size="small"
                v-model="contacted"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in isContactList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <el-button type="primary" class="bgc-bv" round @click="getData()"
              >查询</el-button
            >
            <el-button
              type="primary"
              class="bgc-bv"
              round
              @click="handleCreate()"
              >导出</el-button
            >
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                width="100px"
                :index="indexMethod"
              />
              <el-table-column
                label="学员姓名"
                align="center"
                prop="realname"
                show-overflow-tooltip
                min-width="80"
              />
              <el-table-column
                label="性别"
                align="center"
                prop="sex"
                show-overflow-tooltip
                min-width="60"
              >
                <template slot-scope="scope">
                  {{ scope.row.sex == "1" ? "男" : "女" }}
                </template>
              </el-table-column>
              <el-table-column
                label="工作单位"
                align="center"
                prop="company"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="所属岗位"
                align="center"
                prop="post"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="联系方式"
                align="center"
                prop="phone"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="所属地区"
                align="center"
                prop="areaName"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="当前地址"
                align="center"
                prop="address"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="报名课程"
                align="center"
                prop="courseName"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="报名时间"
                align="center"
                prop="applyTime"
                show-overflow-tooltip
                width="170"
              >
                <template slot-scope="scope">{{
                  scope.row.applyTime | moment
                }}</template>
              </el-table-column>
              <el-table-column
                label="是否联系"
                align="center"
                width="100px"
               fixed="right"
              >
                <div slot-scope="scope" class="flexcc">
                  <el-switch
                    :width="20"
                    v-model="scope.row.contacted"
                    @change="
                      getSwitchUsable(
                        scope.row.contacted,
                        scope.row.courseApplyId
                      )
                    "
                  ></el-switch>
                  <span>{{ scope.row.contacted ? "是" : "否" }}</span>
                </div>
              </el-table-column>
              <el-table-column
                label="备注"
                align="center"
                prop="remark"
                 fixed="right"
                show-overflow-tooltip
                width="170"
              >
              </el-table-column>
              <el-table-column label="操作" align="center"  fixed="right">
                <template slot-scope="scope">
                  <el-button
                    size="mini"
                    type="text"
                    @click="handlePass(scope.row.courseApplyId)"
                    >编辑</el-button
                  >
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
       <el-dialog
      title="编辑"
      :visible.sync="DialogVisible"
      width="50%"
      
      top="2%"
      center
      :before-close="closeDownloadTrainDocument"
    >
      <div>
        <el-form
          :model="ruleForm"
          ref="ruleForm"
          label-width="100px"
          class="Score-ruleForm"
        >
          <el-form-item label="姓名:" prop="realname">
            <el-input v-model="ruleForm.realname" disabled size="small"></el-input>
          </el-form-item>
          <el-form-item label="联系方式:" prop="phone">
           <el-input v-model="ruleForm.phone" disabled size="small"></el-input>
          </el-form-item>
          <el-form-item label="性别:" prop="sex">
             <el-select v-model="ruleForm.sex" placeholder="请选择性别" disabled size="small">
      <el-option label="男" value="1"></el-option>
      <el-option label="女" value="0"></el-option>
    </el-select>
          </el-form-item>
          <el-form-item label="报名课程:" prop="courseName" >
              <el-input v-model="ruleForm.courseName" disabled size="small"></el-input>
          </el-form-item>
          <el-form-item label="工作单位:" prop="company">
              <el-input v-model="ruleForm.company" size="small"></el-input>
          </el-form-item>
          <el-form-item label="所属岗位:" prop="post">
              <el-input v-model="ruleForm.post" size="small"></el-input>
          </el-form-item>
          <el-form-item label="所属地区:" prop="areaId">
              <el-cascader
                :options="areatreeList"
                clearable
                filterable
                :props="propsarea"
                size="small"
                v-model="ruleForm.areaId"
              ></el-cascader>
          </el-form-item>
          <el-form-item label="当前住址:" prop="address">
              <el-input v-model="ruleForm.address" size="small"></el-input>
          </el-form-item>
          <el-form-item label="备注:" prop="remark">
              <el-input v-model="ruleForm.remark" size="small"></el-input>
          </el-form-item>
        </el-form>
         <div class="btn-box flexcc">
              <el-button @click="doCancel" class="bgc-bv">取 消</el-button>
              <el-button class="bgc-bv" @click="doAddSave">确 定</el-button>
            </div>
      </div>
    </el-dialog>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
  name: "teacherList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      realname: "", // 学员姓名
      courseName: "", // 课程名称
      contacted: null, // 是否联系
      // 是否联系 - 下拉数据
      isContactList: [
        {
          label: "是",
          value: true,
        },
        {
          label: "否",
          value: false,
        },
      ],
      signUpTime: [], // 报名时间
      // 机构专题url（用户微信公众号跳转小程序的路径）
      wUrl: "/pages/mechanismSpecial/index?specialSubjectId=",
      DialogVisible:false,
      ruleForm:{},
       // 行政区划
      areatreeList: [],
      // 行政区划 - 匹配字段
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
    };
  },
  computed: {},
  created() {
    this.getTableHeight();
  },
  methods: {
    // 初始化获取列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        realname: this.realname,
        courseName: this.courseName,
        contacted: this.contacted,
      };
      if (this.signUpTime) {
        params.applyStartTime = this.signUpTime[0];
        params.applyEndTime = this.signUpTime[1];
      }
      this.doFetch(
        {
          url: "/miniapp/special/course/apply/pageList",
          params,
          pageNum,
        },
        true,
        2
      );
    },
    // 是否联系
    getSwitchUsable(keyId, enabled) {
      this.$post(
        "/miniapp/special/course/apply/modifyIsContact",
        {
          contacted: keyId,
          courseApplyId: enabled,
        },
        3000,
        false,
        2
      )
        .then((ret) => {
          this.$message({
            type: "success",
            message: ret.message,
          });
          this.getData(-1);
        })
        .catch((err) => {
          return;
        });
    },
    // 导出
    handleCreate(stu, courseId = "") {
      let params = {
        contacted: this.contacted,
        realname: this.realname,
      };
      this.$post(
        "/miniapp/special/course/apply/list/export",
        params,
        3000,
        true,
        2
      ).then((res) => {
        if (res.status == "0") {
          window.location.href = res.data;
        }
      });
    },
    
   // 获取行政区划
    getareatree() {
      this.$post("/sys/area/tree")
        .then((res) => {
          this.areatreeList = res.data || [];
        })
        .catch(() => {
          return;
        });
    },
    /* 编辑 */
    handlePass(courseApplyId) {
      this.DialogVisible = true;
      this.getareatree()
      this.getInfo(courseApplyId)
    },
    getInfo(courseApplyId) {
       this.$post('/miniapp/special/course/apply/getInfo',{courseApplyId},3000,true,2).then(ret => {
         if(ret.status == '0') {
           this.ruleForm = {
             ...ret.data
           }
         }
       })
    },
      // // 编辑保存
    doAddSave() {
      const params = {
        courseApplyId:this.ruleForm.courseApplyId,
      };
      if (this.ruleForm.company) {
        params.company = this.ruleForm.company;
      }
      if (this.ruleForm.post) {
        params.post = this.ruleForm.post;
      }
      if (this.ruleForm.areaId) {
        params.areaId = this.ruleForm.areaId;
      }
      if (this.ruleForm.address) {
        params.address = this.ruleForm.address;
      }
      if (this.ruleForm.remark) {
        params.remark = this.ruleForm.remark;
      }
        this.$post("/miniapp/special/course/apply/modify",
            params,
            3000,
            true,
            2
          )
            .then((res) => {
              if (res.status == 0) {
                this.$message({
                  message: "修改成功",
                  type: "success",
                });
                // setTimeout(() => {
                this.doCancel();
                this.getData(-1)
                // }, 3000);
              }
            })
            .catch((err) => {
              return;
            });
    },
      // 点击取消
    doCancel() {
       this.DialogVisible = false;
    },
  },
  watch: {},
};
</script>
<style lang="less" scoped>
.addlist {
  .el-icon-plus:before {
    content: "\e6d9";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.table-switch {
  display: flex;
  align-items: center;
  justify-content: center;
  .el-switch__core {
    width: 40px !important;
  }
  .el-switch__core:after {
    background-color: #fff;
  }
  span {
    line-height: 20px;
    margin-left: 5px;
  }
}

.operationControl > div > div > span {
  min-width: 6rem;
}

.itemLabel {
  display: inline-block;
  width: 3rem;
}
</style>